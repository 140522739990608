import { isCartExpired, setAnalyticsData } from '../../Utils/commonUtils';
import React from 'react';
import inRange from 'lodash/inRange';
import isEmpty from 'lodash/isEmpty';
import {
  getAnalyticsParamDefaultValue,
  isEmptyArray,
  displayPrice,
  getTernaryValue,
  isCateringDisposition,
  getMainImagePath,
  getBackgroundImageSrc,
  getPercentage,
  getAndCheck,
  getPrice,
  checkIfStoreShutDown,
  toastifyFnWithImage,
  itemFilter,
  getMenuPath,
} from 'common/utilities/utils';
import { translateList } from 'common/utilities/translateUtils';

import Accordian from 'atoms/Accordian/Accordian';
import ImageComponent from 'atoms/Image';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import {
  SESSION_STORAGE_KEYS,
  IMAGE_SIZE_CONSTANTS,
  DATE_FORMATS,
  ANALYTICS_SHARED_CONSTANTS,
  LOCAL_STORAGE_KEYS,
  COUPON_ERROR,
  DISCOUNT_CODE,
  ADDITIONAL_CHARGES,
  APPLIED,
  WICODE,
  CONTOMIZATION_TYPE,
  ASAP,
} from 'common/constants/SharedConstants';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { fetchItemDetailsAction } from '@kfc-global/react-shared/redux/Actions/MenuAction';
import { addItemToCartAction, setUserLastActiveTime } from '@kfc-global/react-shared/redux/Actions/CartAction';
import {
  getCurrentEpochTimeonTimeZone,
  getEpochTimeonTimeZone,
  parseInvalidDateAndFormat,
} from 'common/utilities/dayJsUtils';
import { RecommendedItemAnalytic } from 'common/utilities/analyticsUtils';
import { APPLIED_COUPON } from 'organisms/CouponsLanding/Constants/CouponConstants';
import { CART_PAGE_CONSTANTS } from 'organisms/CartPage/Constants/CartPageConstants';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { TENANT_IMAGE_BASE_URL } from '@kfc-global/react-shared/config/config.constants';

const { Error: ErrorImg } = IMAGE_PATH;
const {
  EDIT,
  CUSTOMISE,
  NOT_AVAILABLE,
  SEE_CUSTOMISED_ITEMS,
  SEE_INCLUDED_ITEMS,
  TOTAL_ORDER_OVER_LIMIT_ERROR,
  TOTAL_ORDER_BETWEEN_LIMIT_ERROR,
  TOTAL_ORDER_LIMIT_MINVALUE,
  TOTAL_ORDER_LIMIT_MAXVALUE,
  AND,
  FOR,
  NO,
  ITEM_NOT_AVAILABLE_AT_STORE,
  CATERING_CART_ERROR_MSG,
  ITEM_CURRENLTY_UNAVAILABLE,
  DISPOSITION_ITEM_NOT_AVAILABLE,
} = TRANSLATE_MAPPING_KEY;

export const modifyData = (
  data,
  listData,
  tenantId,
  currencyConversionFactor,
  addHope,
  carryBag,
  bucketSize,
  country,
  isMobile,
  pdpBackgroundImageData,
  modifierDataFlag,
  showTaxLines,
) => {
  const contentData = {
    cartList: [],
    cartSummary: {},
    tenantId,
    country,
    showTaxLines,
  };
  const { id } = data;
  contentData.cartList =
    tenantId &&
    bucketSize &&
    getCartListData(
      listData,
      tenantId,
      currencyConversionFactor,
      bucketSize,
      isMobile,
      pdpBackgroundImageData,
      modifierDataFlag,
    );
  contentData.bucketId = id;
  contentData.cartSummary = {
    ...getcartSummary(data, listData, currencyConversionFactor, addHope, carryBag),
  };
  return contentData;
};

export const getLanguagesTitle = (obj, data) => {
  const filter = data?.lang && obj?.title?.filter(item => item?.lang === data?.lang);
  return filter?.length ? filter?.[0]?.value : obj?.title?.[0]?.value;
};

export const getcartSummary = (data, listData, currencyConversionFactor, addHope, carryBag) => {
  const {
    inclusiveTaxTotal = 0,
    additionalChargeLines = [],
    discountLines = [],
    paymentDiscountLines = [],
    paymentDiscountTotal = 0,
  } = data;
  const subtotal = data?.subtotal || data?.subTotal;
  let availableList = listData?.filter(
    item =>
      !item.toRemove && item.newFoodLineItem?.item?.name !== addHope && item.newFoodLineItem?.item?.name !== carryBag,
  );
  const quantity = availableList.reduce((acc, food) => +acc + +food.newFoodLineItem?.quantity, 0);
  const additionalCharges = additionalChargeLines?.map(charges => ({
    chargeType: charges?.chargeType || ADDITIONAL_CHARGES,
    amount: (+charges?.amount / currencyConversionFactor || 0).toFixed(2),
  }));
  const discountAmounts =
    discountLines.length &&
    discountLines.map(obj => {
      let errorMessage = '',
        allErrorMessage = [];
      if (obj?.basicErrors?.length) {
        errorMessage = obj?.basicErrors[0]?.message;
        obj?.basicErrors.forEach(msg => {
          allErrorMessage.push(msg?.message);
        });
      } else if (obj?.promoConditionStatus?.length) {
        const errorMsg = obj?.promoConditionStatus.filter(objData => !objData?.isValidCondition);
        errorMessage = errorMsg?.length ? errorMsg[0]?.displayMessage[0]?.value : '';
        if (errorMsg?.length) {
          errorMsg?.forEach(msg => {
            allErrorMessage.push(msg?.displayMessage[0]?.value);
          });
        }
      }
      return {
        couponCode: obj?.couponCode || DISCOUNT_CODE,
        amount: (+obj?.amount / currencyConversionFactor || 0).toFixed(2),
        status: obj.status.name,
        isError: String(obj?.status?.name).toUpperCase() !== APPLIED,
        errorMessages: errorMessage,
        allErrorMessage: allErrorMessage,
        title: getLanguagesTitle(obj, data),
        itemPromo: obj?.itemPromo,
        promoAction: obj?.promoAction,
      };
    });
  const paymentDiscountAmounts =
    paymentDiscountLines.length &&
    paymentDiscountLines.map(obj => {
      let errorMessage = '',
        allErrorMessage = [];
      if (obj?.error) {
        errorMessage = obj?.error?.message;
        allErrorMessage.push(errorMessage);
      } else if (obj?.status) {
        allErrorMessage.push(obj?.status?.name);
      }
      return {
        couponCode: obj?.paymentDiscountCode || WICODE,
        amount: (+obj?.amount / currencyConversionFactor || 0).toFixed(2),
        status: obj?.status?.name?.toUpperCase() || '',
        isError: String(obj?.status?.name).toUpperCase() !== APPLIED,
        errorMessages: errorMessage,
        allErrorMessage: allErrorMessage,
        title: obj?.type?.toLowerCase() || '',
        remainingBalance: (+obj?.remainingBalance / currencyConversionFactor || 0).toFixed(2),
      };
    });
  const taxLines = listData?.[0]?.taxLines?.length ? listData?.[0]?.taxLines : [];
  const totalAdditionalCharges = (+data?.additionalChargeTotal / currencyConversionFactor || 0).toFixed(2);
  return {
    cartId: data?.id || '',
    catalog: data?.catalog,
    quantity,
    taxAmounts: (inclusiveTaxTotal / currencyConversionFactor || 0).toFixed(2),
    taxLines: taxLines,
    additionalCharges,
    totalAdditionalCharges,
    discountAmounts: discountAmounts || [],
    subTotal: (+subtotal / currencyConversionFactor || 0).toFixed(2),
    totalAmount: (+data?.total / currencyConversionFactor || 0).toFixed(2),
    recommendedItems: data?.recommendedItems || [],
    removedAddOnItems: data?.removedAddOnItems || [],
    bottleDepositChargeTotal: data?.bottleDepositChargeTotal || 0,
    paymentDiscountAmounts: paymentDiscountAmounts || [],
    paymentDiscountTotal: (+paymentDiscountTotal / currencyConversionFactor || 0).toFixed(2),
  };
};

export const getCartListData = (
  foodLines = [],
  tenantId = null,
  currencyConversionFactor = null,
  bucketSize = null,
  isMobile = null,
  pdpBackgroundImageData = null,
  modifierDataFlag = null,
) => {
  const { CART_ITEM } = IMAGE_SIZE_CONSTANTS;
  const { CART_IMAGE_WIDTH_DESKTOP, CART_IMAGE_HEIGHT_DESKTOP, CART_IMAGE_WIDTH_MOBILE, CART_IMAGE_HEIGHT_MOBILE } =
    CART_ITEM;
  const containerWidth = isMobile ? CART_IMAGE_WIDTH_MOBILE : CART_IMAGE_WIDTH_DESKTOP;
  const containerHeight = isMobile ? CART_IMAGE_HEIGHT_MOBILE : CART_IMAGE_HEIGHT_DESKTOP;
  const getItemImage = foodItem => {
    return foodItem?.newFoodLineItem?.item?.content
      ? getMainImagePath(foodItem?.newFoodLineItem?.item?.content, containerWidth, containerHeight)
      : `${config(TENANT_IMAGE_BASE_URL)}${tenantId}/images/items/${bucketSize}/${
          foodItem?.newFoodLineItem?.item?.imageName[0]?.value
        }.jpg`;
  };

  return (
    foodLines?.length &&
    foodLines.map(foodItem => {
      return {
        foodLineId: foodItem?.newFoodLineItem?.id,
        foodItemId: foodItem?.newFoodLineItem?.item?.id,
        name: foodItem?.newFoodLineItem?.item?.name,
        foodName: foodItem?.newFoodLineItem?.item?.dname[0]?.value,
        quantity: foodItem?.newFoodLineItem?.quantity,
        unitPrice: foodItem?.newFoodLineItem?.unitPrice / currencyConversionFactor,
        imageData: {
          src: getItemImage(foodItem),
          backgroundsrc: getBackgroundImageSrc(pdpBackgroundImageData, containerWidth, containerHeight),
          altText: foodItem?.newFoodLineItem?.item?.dname[0]?.value,
        },
        modifiers: foodItem?.newFoodLineItem?.item?.modGrps,
        shortDescription: foodItem?.newFoodLineItem?.item?.shortDescription[0]?.value,
        customizable: foodItem?.newFoodLineItem?.item?.customizable,
        toRemove: foodItem?.toRemove,
        requireModification: foodItem?.requireModification,
        menuPrice: foodItem?.menuPrice,
        cartPrice: foodItem?.cartPrice,
        discountComponents: foodItem?.newFoodLineItem?.discountComponents,
        custom: foodItem?.newFoodLineItem?.item?.custom,
        isProductPromo: foodItem?.newFoodLineItem?.item?.isProductPromo,
        categoryUrl: foodItem?.newFoodLineItem?.item?.categoryUrl,
        categoryId: foodItem?.newFoodLineItem?.item?.categoryId,
        freeItem: foodItem?.newFoodLineItem?.item?.basePrice === 0,
        itemUnitPrice: foodItem?.newFoodLineItem?.item?.unitPrice / currencyConversionFactor,
      };
    })
  );
};

export const getModifiers = (mods = []) => {
  let modArr =
    mods.length > 0
      ? mods
          .map(modItem => modItem?.modifiers?.map(mod => mod?.dname[0]?.value).join(', '))
          .join(', ')
          .split(', ')
      : [];
  return modArr.filter(item => item !== '');
};

export const getPayloadForRemoveFromCart = (
  cartItems,
  productMapByItemId = [],
  qtyFlag = '',
  discountAmounts = [{ couponCode: '', amount: '' }],
  currencyShortName,
  couponData,
  discountAmountData = '',
) => {
  let couponCode = '',
    amount = '';
  if (Array.isArray(discountAmounts) && discountAmounts?.length > 0) {
    ({ couponCode, amount } = discountAmounts[0]);
  }
  return cartItems.map((item, idx) => {
    let item_variant_array = [];
    if (!isEmptyArray(item?.modifiers)) {
      item?.modifiers?.forEach(modifier => {
        item_variant_array.push(modifier?.name);
      });
    }
    const { categoryId: id = 'NA', categoryName: name = 'NA' } = productMapByItemId?.[item.foodItemId] ?? {};
    const baseItemsCstmz = [];
    const saucesCstmz = [];
    const sidesCstmz = [];
    const drinksCstmz = [];
    cartItems?.map(el => {
      const custItemName = item?.modifiers?.[0]?.name;
      const modData = el?.modifiers;
      modData?.map(item => {
        if (item?.modifiers?.[0]?.quantity > 0 && !item?.modifiers?.[0]?.isDefault) {
          if (item?.name?.includes(CONTOMIZATION_TYPE?.GRAVY) || item?.name?.includes(CONTOMIZATION_TYPE?.SIDE)) {
            sidesCstmz.push(custItemName);
          }
          if (item?.name?.includes(CONTOMIZATION_TYPE?.DRINK)) {
            drinksCstmz.push(custItemName);
          }
          if (item?.name?.includes(CONTOMIZATION_TYPE?.SAUCES)) {
            saucesCstmz.push(custItemName);
          }
          if (
            !item?.name?.includes(CONTOMIZATION_TYPE.SIDE) &&
            !item?.name?.includes(CONTOMIZATION_TYPE?.DRINK) &&
            !item?.name?.includes(CONTOMIZATION_TYPE?.GRAVY) &&
            !item?.name?.includes(CONTOMIZATION_TYPE?.SAUCES)
          ) {
            baseItemsCstmz.push(custItemName);
          }
        }
      });
    });
    return {
      item_name: item?.foodName || 'NA',
      item_id: `${item?.foodItemId || 'NA'}`,
      price: +(item?.cartPrice / item?.quantity).toFixed(2),
      coupon: couponCode || couponData,
      discount: +amount || discountAmountData,
      currency: currencyShortName,
      item_category: name,
      item_variant: isEmpty(item?.modifiers) ? 'NA' : item_variant_array.toString(),
      item_list_name: name,
      item_list_id: id,
      index: idx,
      quantity: getAnalyticsParamDefaultValue(qtyFlag, 'removeAllQty', 'quantityModified', item?.quantity, 1),
      originalPrice: cartItems?.[0]?.itemUnitPrice?.toString(),
      baseItemsCustomisation: itemFilter(baseItemsCstmz),
      saucesCustomisation: itemFilter(saucesCstmz),
      sidesCustomisation: itemFilter(sidesCstmz),
      drinksCustomisation: itemFilter(drinksCstmz),
    };
  });
};

/**
 * @tenant  au, in
 * @param {*} flag - Boolean
 * @returns String css classname
 */
export const getImageClass = flag => {
  if (flag) {
    return 'unavailable-image cart-food-image-sopac';
  } else {
    return 'cart-food-image-sopac';
  }
};

/**
 * @tenant  au, in
 * @param {*} flag - Boolean
 * @returns String css classname
 */
export const getfullScaleImage = flag => {
  return isEmpty(flag) ? 'full-scale-img' : 'zoom-scale';
};
/**
 * @tenant  au, in
 * @param {*} flag - Boolean
 * @returns String css className
 */
export const getCartFoodClassName = flag => {
  if (flag) {
    return 'outofstock cart-highlight cart-food-item-container';
  } else {
    return 'cart-food-item-container';
  }
};

/**
 * @tenant  au
 * @param {*} item - Object
 * @param {*} tenantReducer - Object
 * @param {*} isCustom - Boolean
 * @returns accordian component having list item
 */
export const customAccordionFoodItem = (item, tenantReducer, isCustom, customizable) => {
  const showCartItemsCustomAccordian = tenantReducer?.componentOptions?.web?.cartPage?.showCartItemsCustomAccordian;
  return showCartItemsCustomAccordian && item?.modifiers?.[0]?.modifiers?.length > 0 ? (
    <Accordian
      header={item.foodName}
      subHeaderText={isCustom ? translateWithI18Next('custom') : ''}
      subHeaderClass={'custom-tag'}
    >
      <div className='cart-food-modifier'>{getCustomisationList(item?.modifiers, tenantReducer, customizable)}</div>
    </Accordian>
  ) : (
    <div className='cart-food-modifier'>{getCustomisationList(item?.modifiers, tenantReducer, customizable)}</div>
  );
};

const getSubModifiers = ({ subModifiers, flag, tenantReducer, customizable }) => {
  return (
    subModifiers?.modGrps?.length &&
    !flag && (
      <>
        <li style={{ listStyleType: 'disc' }}>{`${subModifiers?.quantity}x ${
          subModifiers?.dname?.[0]?.value
        } ${getPriceDetails(subModifiers?.unitPrice, subModifiers?.quantity, tenantReducer)}`}</li>
        {getCustomisationList(subModifiers?.modGrps, tenantReducer, customizable, true)}
      </>
    )
  );
};

const getCustomSubModifiers = ({ subModifiers, flag, tenantReducer, customizable }) => {
  if ((!flag && customizable) || subModifiers?.custom) {
    let isModal = flag || !customizable;
    return (
      <li style={{ listStyleType: 'disc' }}>
        {!subModifiers?.isAdded && subModifiers?.custom && isModal
          ? `${translateWithI18Next(NO)} ${subModifiers?.dname?.[0]?.value} (-)`
          : `${subModifiers?.quantity}x ${subModifiers?.dname?.[0]?.value} ${getPriceDetails(
              subModifiers?.unitPrice,
              subModifiers?.quantity,
              tenantReducer,
            )}`}
      </li>
    );
  }
};

/**
 * @tenant  au
 * @param {*} foodLineItemModifiers - Array of Objects having modifier details
 * @param {*} tenantReducer - Object
 * @returns list items of modifier
 */
export const getCustomisationList = (foodLineItemModifiers, tenantReducer, customizable, flag = false) => {
  return (
    <ul>
      {foodLineItemModifiers?.map(modifier => {
        if (modifier?.modifiers?.length) {
          return modifier?.modifiers?.map(
            subModifiers =>
              getSubModifiers({ subModifiers, flag, tenantReducer, customizable }) ||
              getCustomSubModifiers({ subModifiers, flag, tenantReducer, customizable }),
          );
        } else {
          return null;
        }
      })}
    </ul>
  );
};

/**
 * @tenant  au
 * @param {*} price - Number
 * @param {*} tenantReducer - Object
 * @returns String having Price details
 */
export const getPriceDetails = (price, quantity, tenantReducer) => {
  const { currencySymbol, currencyConversionFactor, taxAmountRoundOff } = tenantReducer;
  return price === 0
    ? ''
    : `(+${displayPrice(price * +quantity, { currencySymbol, currencyConversionFactor }, '', taxAmountRoundOff)})`;
};

/**
 * @tenant  au
 * @param {*} totalAmount - Number
 * @param {*} maxOrderAmount - Number
 * @param {*} minOrderAmount - Number
 * @param {*} currencyConversionFactor - Number
 * @returns Object
 */
export const maxOrderLimitForStore = (totalAmount, maxOrderAmount, minOrderAmount, currencyConversionFactor) => {
  // currencyCOnversionFactor to be included once OS API is updated accordingly REF#: RENDEV-7468
  let maxOrderLimit = maxOrderAmount / currencyConversionFactor;
  let minOrderLimit = minOrderAmount / currencyConversionFactor;
  let overLimitFlag = maxOrderAmount > 0 ? Number(totalAmount) > maxOrderLimit : false;
  let betweenLimitFlag = maxOrderAmount > 0 ? !inRange(totalAmount, minOrderLimit, maxOrderLimit) : false;
  return {
    overLimitFlag,
    betweenLimitFlag,
    maxOrderLimit,
    minOrderLimit,
  };
};

/**
 *
 * @param {*} totalAmount - String
 * @param {*} currencySymbol - String
 * @param {*} currencyConversionFactor - Number
 * @param {*} maxOrderAmount - Number
 * @param {*} minOrderAmount - Number
 * @returns JSX
 */

export const totalOrderBetweenLimitErrorMessage = (
  currencySymbol,
  maxOrderLimit,
  minOrderLimit,
  totalOrderErrorObject,
) => {
  const errorMessage = totalOrderErrorObject?.totalOrderBetweenLimitError;
  return errorMessage?.includes(TOTAL_ORDER_LIMIT_MINVALUE)
    ? errorMessage
        ?.replace(TOTAL_ORDER_LIMIT_MINVALUE, `${currencySymbol + minOrderLimit}`)
        .replace(TOTAL_ORDER_LIMIT_MAXVALUE, `${currencySymbol + maxOrderLimit}`)
    : `${errorMessage} ${currencySymbol + minOrderLimit} ${totalOrderErrorObject?.and} ${
        currencySymbol + maxOrderLimit
      }`;
};

export const totalOrderLimitErrorMessage = (currencySymbol, maxOrderLimit, totalOrderErrorObject) => {
  const errorMessage = totalOrderErrorObject?.totalOrderLimitErrorMessage;
  return errorMessage?.includes(TOTAL_ORDER_LIMIT_MAXVALUE)
    ? errorMessage?.replace(TOTAL_ORDER_LIMIT_MAXVALUE, `${currencySymbol + maxOrderLimit}`)
    : `${errorMessage} ${currencySymbol + maxOrderLimit}`;
};
export const cartSummaryErrorMessage = ({
  totalAmount,
  currencySymbol,
  currencyConversionFactor,
  maxOrderAmount,
  minOrderAmount,
  checkoutButtonDisabled,
  isCateringDispositionVal,
  totalOrderErrorObject,
}) => {
  const { overLimitFlag, betweenLimitFlag, maxOrderLimit, minOrderLimit } = maxOrderLimitForStore(
    totalAmount,
    maxOrderAmount,
    minOrderAmount,
    currencyConversionFactor,
  );
  return (
    (overLimitFlag || betweenLimitFlag || (isCateringDispositionVal && checkoutButtonDisabled)) && (
      <div className={`cart-summary-error-total-exceeds ${overLimitFlag ? 'overlimit-error' : 'betweenlimit-error'}`}>
        <ImageComponent srcFile={IMAGE_PATH.Ellipse} className='cart-summary-error-icon' />
        <span className='cart-summary-error-message'>
          {getAndCheck(overLimitFlag, !isCateringDispositionVal)
            ? totalOrderLimitErrorMessage(currencySymbol, maxOrderLimit, totalOrderErrorObject)
            : (betweenLimitFlag &&
                totalOrderBetweenLimitErrorMessage(
                  currencySymbol,
                  maxOrderLimit,
                  minOrderLimit,
                  totalOrderErrorObject,
                )) ||
              `${translateWithI18Next(CATERING_CART_ERROR_MSG)}`}
        </span>
      </div>
    )
  );
};

/**
 * @tenant  au
 * @param {*} flatResponse - Array of Objects
 * @param {*} product - Object
 * @returns Object
 */
export const categoriesURLFromProductID = (flatResponse, product) => {
  return flatResponse?.categoryMapByUrl?.[product?.categoryUrl] ?? {};
};

/**
 * @tenant  au
 * @param {*} flatResponse - Object
 * @param {*} product - Object
 * @param {*} categories - Array of Objects
 * @returns String pdp URL
 */
export const generatePDPurl = (flatResponse, product, dispositionType) => {
  const categoryObj = categoriesURLFromProductID(flatResponse, product);
  const { url: prod_url } = flatResponse?.productMapByItemId?.[product?.foodItemId] ?? {};
  const baseURL = getMenuPath(dispositionType);
  return `${baseURL}/${categoryObj?.url}/${prod_url}`;
};

/**
 * @tenant  au
 * @param {*} isCustom - Boolean true or false
 * @returns String
 */
export const customiseEditButtonText = isCustom => {
  return isCustom ? translateWithI18Next(EDIT) : translateWithI18Next(CUSTOMISE);
};

/**
 * @tenant - au
 * @param {*} isCustom - Boolean true or false
 * @returns String
 */
export const itemAccordionHeader = isCustom => {
  return isCustom ? translateWithI18Next(SEE_CUSTOMISED_ITEMS) : translateWithI18Next(SEE_INCLUDED_ITEMS);
};

/**
 * @tenant  au
 * @param {*} isRemove - Boolean
 * @returns JSX
 */
export const CheckForNotAvailable = ({ children, isRemove }) => {
  return (
    <>
      {isRemove && (
        <div className='cart-outofstock-container'>
          <div data-testid='outofstock-text' className='cart-outofstock-text'>
            <ImageComponent srcFile={ErrorImg} className='location-alert-icon' />
            <span className='outoftext-span'>{children}</span>
          </div>
        </div>
      )}
    </>
  );
};

/**
 * @tenant  au
 * @param {*} segmentedData - Object
 * @returns Boolean
 */
export const checkForSegmentedHelpData = segmentedData => {
  const { faqData = {}, contactData = {} } = segmentedData;
  return Boolean(faqData?.faqButtonText) || Boolean(contactData?.callButtonText);
};

/**
 * @tenant  au
 * @param {*} item - Object
 * @param {*} accordionHeader - Object
 * @param {*} tenantReducer - Object
 * @param {*} isCustom - Boolean
 * @returns return Accordian or <div></div> tag depending on the customizable flag from item Object
 */

export const getItemName = (item, accordionHeader, tenantReducer, isCustom) => {
  return item?.modifiers?.length && item?.modifiers?.[0]?.modifiers?.length > 0 && (item?.customizable || isCustom) ? (
    customAccordionFoodItem(accordionHeader, tenantReducer, isCustom, item.customizable)
  ) : (
    <div className='nonCustomizable-foodName'>{item?.foodName}</div>
  );
};

/**
 * @tenant au
 * @returns AddToCartHandler method for Add-ons
 */
export const indulgentAddToCartHandler = ({
  index,
  id,
  customizable,
  dispatch,
  cartReducer,
  tenantReducer,
  catalogs,
  flatResponse,
  category_id,
  userType,
  productId = '',
  ruleName = '',
  recommendationType = '',
  IndulgentData,
  couponCode = '',
  discountTotal = '',
  callBackMethod = () => {},
  errorBackMethod = () => {},
  dispositionType = '',
}) => {
  const {
    id: categoryId = '',
    name: categoryName = '',
    url: categoryUrl = '',
  } = flatResponse?.categoryMapById?.[category_id] ?? {};
  /* setting true value to addOnsCartbtnClick, to avoid the toast onclick of reorder button */
  sessionStorage.setItem(SESSION_STORAGE_KEYS.INDULGENT_ADDONS_CART_CLICK, true);
  if (!customizable) {
    dispatch(
      addItemToCartAction(
        {
          params: {
            basketId: cartReducer?.cartData?.id,
          },
          tenantId: tenantReducer?.tenantId,
        },
        [
          {
            Id: id,
            categoryId: categoryId,
            quantity: 1,
            modGrps: [],
            categoryName,
            categoryUrl,
            parentId: productId,
            ruleName,
            recommendationType,
          },
        ],
        () => {
          callBackMethod(id);
        },
        () => {
          errorBackMethod();
        },
      ),
    );
    dispatch(setUserLastActiveTime(new Date()));
  } else {
    dispatch(
      fetchItemDetailsAction({
        tenantId: tenantReducer?.tenantId,
        catalogName: catalogs,
        itemId: id,
      }),
    );
  }
  const product = IndulgentData?.filter(item => id === item.id)[0];
  const availabilityObj = product?.availability && product?.availability?.[0];
  const itemsList = [
    {
      item_name: product?.dname[0]?.value,
      item_id: id,
      price: +getPrice(availabilityObj, tenantReducer?.currencyConversionFactor).toFixed(2),
      currency: tenantReducer?.currencyShortName,
      item_category: categoryName,
      item_list_name: ANALYTICS_SHARED_CONSTANTS.CART_CROSS_SELL,
      item_list_id: categoryId,
      index: index,
      quantity: 1,
      coupon: couponCode,
      discount: discountTotal / tenantReducer?.currencyConversionFactor,
      originalPrice: +getPrice(availabilityObj, tenantReducer?.currencyConversionFactor).toFixed(2).toString(),
      baseItemsCustomisation: '',
      saucesCustomisation: '',
      sidesCustomisation: '',
      drinksCustomisation: '',
    },
  ];

  const totalAmount = +getPrice(availabilityObj, tenantReducer?.currencyConversionFactor).toFixed(2);
  RecommendedItemAnalytic(
    userType,
    itemsList,
    totalAmount,
    couponCode,
    tenantReducer?.currencyShortName,
    dispositionType,
  );
};

export const getIndulgentAddOnContainerSize = (isMobile, windowInnerWidth) => {
  const { INDULGENT_ITEM } = IMAGE_SIZE_CONSTANTS;
  const { ITEM_IMAGE_HEIGHT_MOBILE, ITEM_IMAGE_HEIGHT_DESKTOP, ITEM_WIDTH_DESK_PRCNT } = INDULGENT_ITEM;
  const containerHeight = isMobile ? ITEM_IMAGE_HEIGHT_MOBILE : ITEM_IMAGE_HEIGHT_DESKTOP;
  const containerWidth = isMobile ? windowInnerWidth : getPercentage(windowInnerWidth, ITEM_WIDTH_DESK_PRCNT);
  return { containerHeight, containerWidth };
};

export const getIndulgentAddOnCardSize = () => {
  const { INDULGENT_ITEM } = IMAGE_SIZE_CONSTANTS;
  const { INDULGENT_IMAGE_WIDTH, INDULGENT_IMAGE_HEIGHT } = INDULGENT_ITEM;
  const cardHeight = INDULGENT_IMAGE_HEIGHT;
  const cardWidth = INDULGENT_IMAGE_WIDTH;
  return { cardHeight, cardWidth };
};

export const getImageSize = isMobileSize => {
  const { PDP_MAIN_CONTAINER } = IMAGE_SIZE_CONSTANTS;
  const { PDP_IMAGE_WIDTH_DESKTOP, PDP_IMAGE_WIDTH_MOBILE, PDP_IMAGE_HEIGHT_DESKTOP, PDP_IMAGE_HEIGHT_MOBILE } =
    PDP_MAIN_CONTAINER;

  if (isMobileSize) {
    return { containerHeight: PDP_IMAGE_HEIGHT_MOBILE, containerWidth: PDP_IMAGE_WIDTH_MOBILE };
  } else {
    return { containerHeight: PDP_IMAGE_HEIGHT_DESKTOP, containerWidth: PDP_IMAGE_WIDTH_DESKTOP };
  }
};

export const isOrderPickupTimePassed = (type, timeZone, storeSelectedDate, storeSelectedTime) => {
  if (isCateringDisposition(type)) {
    const currentEpochTime = getCurrentEpochTimeonTimeZone(timeZone);
    const formatedCateringDateTime = `${storeSelectedDate} ${parseInvalidDateAndFormat(
      storeSelectedTime,
      DATE_FORMATS.H_MM_AA,
      DATE_FORMATS.HH_mm,
    )}`;
    const cateringEpochTime = getEpochTimeonTimeZone(formatedCateringDateTime, timeZone);
    return cateringEpochTime >= currentEpochTime + 24 * 60 * 60;
  }
  return true;
};

export const cartCheckOutHandler = ({
  storesWithStatus,
  dispositionData,
  confirmChangesHandler,
  CheckoutDetailsClick,
  setShowCateringAlert,
  setCheckoutButtonDisabled,
  FROMCHECKOUT,
  scheduleTimeCheck,
  setOrderScheduleModal,
  sliceDispatch = null,
  resetPWAstorage = null,
  setShowAsapAlert,
  AsapCheck,
}) => {
  sliceDispatch(
    resetPWAstorage({
      key: LOCAL_STORAGE_KEYS.PROMO_PRODUCT_CART,
    }),
  );
  const storeTimeZone = storesWithStatus?.[0]?.summary?.timeZoneIANA;
  if (AsapCheck) {
    setShowAsapAlert(true);
  } else if (
    isOrderPickupTimePassed(dispositionData?.type, storeTimeZone, dispositionData?.date, dispositionData?.time)
  ) {
    confirmChangesHandler(FROMCHECKOUT, scheduleTimeCheck, setOrderScheduleModal);
    CheckoutDetailsClick();
  } else {
    setShowCateringAlert(true);
    setCheckoutButtonDisabled(true);
  }
};

export { isCartExpired, setAnalyticsData };

export const setPriceLimitInLocalStorage = menuData => {
  const { maxOrderAmount, minOrderAmount } = menuData;
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.PRICE_LIMITS,
    JSON.stringify({
      maxOrderAmount,
      minOrderAmount,
    }),
  );
};
export const getModifiersList = (foodLineItemModifiers, modifiersArray = []) => {
  foodLineItemModifiers?.length &&
    foodLineItemModifiers?.forEach(L1Modifiers => {
      L1Modifiers?.modifiers?.length &&
        L1Modifiers?.modifiers.forEach((L2Modifiers, index) => {
          modifiersArray.push(L2Modifiers);
          if (L2Modifiers?.modGrps?.length && L1Modifiers?.modifiers?.length !== index) {
            getModifiersList(L2Modifiers?.modGrps, modifiersArray);
          }
        });
    });
  return modifiersArray;
};
export const getExcludedModifiersArray = (foodLineItemModifiers, exclusionsResponse) => {
  let excludedModifiersArray = [];
  const modifiersList = getModifiersList(foodLineItemModifiers);
  modifiersList.forEach(modifiers =>
    exclusionsResponse?.some(
      excludedItem => excludedItem?.itemId === modifiers?.id && excludedModifiersArray.push(excludedItem?.itemId),
    ),
  );
  return excludedModifiersArray;
};
export const translateErrMsg = ({
  couponError,
  MAX_VOUCHER_LIMIT_EXCEEDED,
  voucherLimitExceededErr,
  maxSubTotalValueCovered,
  MAX_SUBTOTAL_VALUE_COVERED,
}) => {
  const { COUPON_ERROR_CODES } = CART_PAGE_CONSTANTS;
  if (couponError?.errorCode) {
    const errorCode =
      couponError?.errorCode === COUPON_ERROR_CODES.INVALID_ARGUMENT
        ? COUPON_ERROR_CODES.INVALID_COUPON
        : couponError?.errorCode;
    return COUPON_ERROR + errorCode;
  } else if (voucherLimitExceededErr) {
    return MAX_VOUCHER_LIMIT_EXCEEDED;
  } else if (maxSubTotalValueCovered) {
    return MAX_SUBTOTAL_VALUE_COVERED;
  } else {
    return '';
  }
};
const getCartListRemoveStatus = (timeBasedCetegory, cartList) => {
  return !timeBasedCetegory?.isTimeExpired && cartList?.length && cartList?.filter(list => list.toRemove).length > 0;
};
export const setCheckoutButtonDisabledErr = params => {
  const {
    isBasketDetailsLoading,
    showPromoRewardsMessage,
    overLimitFlag,
    betweenLimitFlag,
    isModelClosed,
    unMetConditionFound,
    unMetPaymentDiscountConditionFound,
    checkoutButtonDisabled,
    timeBasedCetegory,
    cartList,
    storesWithStatus,
    enableAddGiftCardOrVoucher,
    isCouponLoading,
    userOrderState,
  } = params;
  const timeVal = userOrderState?.scheduleOrderData?.selectedDateTimeInfo?.time;

  const checkIfStoreShutDownErr = checkIfStoreShutDown(storesWithStatus) && (timeVal === '' || timeVal === ASAP);
  const getCartListRemoveStatusErr = getCartListRemoveStatus(timeBasedCetegory, cartList);
  const getCartOverlimitErr = overLimitFlag || betweenLimitFlag || isModelClosed;
  const promosConditionUnmetErr = showPromoRewardsMessage ? unMetConditionFound : false;
  const getGiftCardVouchersDiscountUnmetErr =
    enableAddGiftCardOrVoucher && !showPromoRewardsMessage ? unMetPaymentDiscountConditionFound : false;
  return (
    isBasketDetailsLoading ||
    getCartOverlimitErr ||
    promosConditionUnmetErr ||
    getGiftCardVouchersDiscountUnmetErr ||
    checkoutButtonDisabled ||
    getCartListRemoveStatusErr ||
    checkIfStoreShutDownErr ||
    isCouponLoading
  );
};
export const getAppliedCoupon = (discountLines, couponCode) => {
  if (discountLines) {
    const appliedCoupon = discountLines?.filter(discount => discount?.couponCode === couponCode);
    return appliedCoupon?.[0]?.status?.name?.toUpperCase() === APPLIED_COUPON;
  }

  return false;
};

export const getToastMsg = couponObj => {
  const {
    discountLines,
    couponCode,
    showPromoRewardsMessage,
    currencySymbol,
    currencyConversionFactor,
    paymentDiscountLines,
  } = couponObj;
  const { TOAST_NOTIFICATION } = CART_PAGE_CONSTANTS;
  const imageTemp = <ImageComponent srcFile={IMAGE_PATH.tickWhiteToast} />;
  let toastMessage;
  if ((discountLines?.length && getAppliedCoupon(discountLines, couponCode)) || paymentDiscountLines?.length) {
    if (paymentDiscountLines?.length) {
      toastMessage =
        currencySymbol +
        (+paymentDiscountLines[paymentDiscountLines?.length - 1]?.amount / currencyConversionFactor || 0).toFixed(2) +
        ' ' +
        translateWithI18Next(TOAST_NOTIFICATION.APPLIED);
    } else {
      toastMessage = translateWithI18Next(TOAST_NOTIFICATION.COUPON_APPLIED);
    }
    toastifyFnWithImage(toastMessage, imageTemp, 'coupon-applied-toast');
  }
};

export const filterPromoItem = (foodItemId, disCountLinesItems) => {
  const promoItemDetails = [];
  disCountLinesItems?.forEach(item => {
    const itemsList = item?.itemPromo?.filter(promo => promo?.itemId === foodItemId);
    if (itemsList?.length > 0) {
      promoItemDetails.push(itemsList);
    }
  });
  return promoItemDetails?.[0] || [];
};

export const getConditionCheckForRemoveButton = (removeCTAFlag, isFreeItem, isItemToRemove) => {
  if (isFreeItem) {
    return !removeCTAFlag ? true : isItemToRemove;
  } else {
    return true;
  }
};

export const checkIsBundle = id => {
  const pattern = /^I-\d+-\d+$/;
  return pattern.test(id);
};

export const getItemUnavailableMessage = ({ dispositionType, itemNotAvailableAtStoreMsg }) => {
  if (itemNotAvailableAtStoreMsg) {
    return translateWithI18Next(ITEM_NOT_AVAILABLE_AT_STORE);
  }

  return translateList([NOT_AVAILABLE, FOR, dispositionType]);
};
