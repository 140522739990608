import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { ButtonComp } from 'atoms/Buttons';
import NOT_FOUND_404_ERROR from '../Constants/NotFoundErrorConstants';
import { isMobileDevice } from 'common/utilities/utils';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
export const NotFoundError = ({ showTakeMeHomeBtn = true, history }) => {
  const { ERROR_CODE, THERE_NO_FRIED_TEXT, CHICKEN_PAGE_TEXT, TAKE_ME_HOME_TEXT } = NOT_FOUND_404_ERROR;
  const { kfcCup, kfc_bucket_mob } = IMAGE_PATH;
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const gotToHome = () => {
    history.push({
      pathname: '/',
    });
  };

  return (
    <>
      <div className='not-found-container'>
        <div className='not-found-kfc-bucket-part'>
          <ReactSVG src={isMobileDevice(window.innerWidth) ? kfc_bucket_mob : kfcCup} />
        </div>
        <div className='not-found-error-part'>
          <p className='not-found-error-text red-text'>{ERROR_CODE}</p>
          <p className='not-found-error-text'>{translateWithI18Next(THERE_NO_FRIED_TEXT)}</p>
          <p className='not-found-error-text'>{translateWithI18Next(CHICKEN_PAGE_TEXT)}</p>
          {showTakeMeHomeBtn && (
            <ButtonComp data-testid='take-me-homeid' className='take-me-home-button' onClick={gotToHome}>
              {translateWithI18Next(TAKE_ME_HOME_TEXT)}
            </ButtonComp>
          )}
        </div>
      </div>
    </>
  );
};
