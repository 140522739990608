import React, { useEffect } from 'react';
import { PAYMENT_TENDER_TYPES } from 'common/constants/SharedConstants';
import { redirectToOrderProcessingPage, redirectToOTPPage } from 'common/utilities/utils';
import { getPaymentOptionsTemplate } from './templates/PaymentViewTemplates';
import KBankPaymentCreditDebitCardForm from '../Forms/KBankPaymentCreditDebitCardForm';
import TrueMoneyWalletTemplate from 'organisms/Payment/presentation/templates/TrueMoneyWalletTemplate';
import KBankPaymentQRCodeForm from '../Forms/KBankPaymentQRCodeForm';
import {
  dispatchPaymentChargeApiRequest,
  dispatchGetKbankSavedCardsApiRequest,
  dispatchConfigDynamicApiRequest,
  dispatchGenerateOtpApiRequest,
  showToastMessage,
  removeKBANKCCFromScript,
  removeQRcodeFormScript,
} from 'organisms/Payment/utils/ThailandPaymentUtils';
import { setPWAstorage } from '@kfc-global/react-shared/redux/Slices/PwaStorageSlice';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
const ThailandPaymentView = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  const { isClickAddPayment } = checkoutDetails;
  const { PAYMENT_STORAGE } = PAYMENT_CONSTANTS;

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    checkoutDetails?.tender?.name !== PAYMENT_TENDER_TYPES?.QR_PAY && removeQRcodeFormScript();
    checkoutDetails?.tender?.name !== PAYMENT_TENDER_TYPES?.CREDIT_CARD && removeKBANKCCFromScript();
    if (checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CREDIT_CARD) {
      updateCheckoutDetails({ enablePaymentProviderTHPlaceOrderBtn: true });
    } else {
      updateCheckoutDetails({ enablePaymentProviderTHPlaceOrderBtn: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.tender?.name]);
  useEffect(() => {
    if (checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.TRUE_MONEY_WALLET) {
      updateCheckoutDetails({ enableTrueMoneyWallet: true });
    } else {
      updateCheckoutDetails({
        placeOrderButtonEnabled: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.tender?.name]);

  useEffect(() => {
    checkoutDetails?.tender?.name !== PAYMENT_TENDER_TYPES?.QR_PAY &&
      updateCheckoutDetails({
        qrOrderId: '',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.tender?.name]);

  useEffect(() => {
    if (checkoutDetails?.paymentProvider) {
      updateCheckoutDetails({
        enablePaymentCardInputPanel: true,
        enablePaymentProviderTHPlaceOrderBtn: false,
        publicTokenApiKey: '',
        KbankToken: '',
        defaultCardId: '',
        enableTrueMoneyWallet: true,
        cardid: null,
        cancelledQRCode: false,
        isLastUsedPaymentIsFromSavedCard: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.paymentProvider]);
  useEffect(() => {
    if (checkoutDetails?.isPaymentTypeSelected && checkoutDetails?.cardid) {
      updateCheckoutDetails({
        tender: {
          name: checkoutDetails?.tender?.name,
          payload: { tender: checkoutDetails?.tender?.name },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isPaymentTypeSelected, checkoutDetails?.cardid]);

  useEffect(() => {
    // on click of place order button after generate otp API return error
    checkoutDetails?.isClickPlaceOrder &&
      checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.TRUE_MONEY_WALLET &&
      checkoutDetails?.trueMoneyWalletGeneratetOTPErrorResponse &&
      updateCheckoutDetails({
        updatedTMW: true,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isClickPlaceOrder]);

  useEffect(() => {
    if (isClickAddPayment && checkoutDetails?.userParams?.loggedIn) {
      dispatchGetKbankSavedCardsApiRequest(checkoutDetails, updateCheckoutDetails);
      checkoutDetails?.lastUsedPaymentMethod &&
        updateCheckoutDetails({
          isPaymentTypeSelected: true,
          enableTrueMoneyWallet: checkoutDetails?.lastUsedPaymentMethod === PAYMENT_TENDER_TYPES?.TRUE_MONEY_WALLET,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickAddPayment]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    checkoutDetails?.isUpdatedOrderActionRequest && updateCheckoutDetails({ dispatchOrderAction: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isUpdatedOrderActionRequest]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    if (checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.PAY_BY_CASH) {
      checkoutDetails?.orderId &&
        checkoutDetails?.isCreateOrderValidateCompleted &&
        redirectToOrderProcessingPage(props);
    }
    if (checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.TRUE_MONEY_WALLET) {
      checkoutDetails?.orderId &&
        checkoutDetails?.isCreateOrderValidateCompleted &&
        updateCheckoutDetails({
          updatedTMW: true,
        });
    } else if (checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.QR_PAY) {
      checkoutDetails?.orderId &&
        checkoutDetails?.isCreateOrderValidateCompleted &&
        dispatchConfigDynamicApiRequest(props);
    } else {
      checkoutDetails?.orderId &&
        checkoutDetails?.isCreateOrderValidateCompleted &&
        checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CREDIT_CARD &&
        dispatchPaymentChargeApiRequest(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isCreateOrderValidateCompleted]);

  /*********************************************************************
   * Call generate OTP request
   *
   *********************************************************************/

  useEffect(() => {
    checkoutDetails?.dispatch(
      setPWAstorage({
        key: PAYMENT_STORAGE?.CHECKOUT_DETAILS,
        value: {
          collectionPoint: checkoutDetails?.collectionPoint,
          instruction: checkoutDetails?.instruction,
          checkInSummary: checkoutDetails?.checkInSummary,
        },
      }),
    );
    checkoutDetails?.updatedTMW && dispatchGenerateOtpApiRequest(props);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.updatedTMW]);

  /*********************************************************************
   *trigger toast meesage after TMW mobile number is not valid
   *
   *********************************************************************/

  useEffect(() => {
    checkoutDetails?.trueMoneyWalletGeneratetOTPErrorResponse &&
      showToastMessage(checkoutDetails?.trueMoneyWalletGeneratetOTPErrorResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.trueMoneyWalletGeneratetOTPErrorResponse]);

  /*********************************************************************
   * Redirect to OTP screen after TMW mobile number is valid
   *
   *********************************************************************/

  useEffect(() => {
    checkoutDetails?.trueMoneyWalletGeneratetOTPResponse && redirectToOTPPage(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.trueMoneyWalletGeneratetOTPResponse]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    checkoutDetails?.orderId &&
      checkoutDetails?.tender?.name &&
      checkoutDetails?.isPaymentTransationCompleted &&
      redirectToOrderProcessingPage(props);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isPaymentTransationCompleted]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    checkoutDetails?.orderId &&
      checkoutDetails?.isCreateOrderValidateCompleted &&
      checkoutDetails?.isPaymentTransationDeclined &&
      redirectToOrderProcessingPage(props);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isPaymentTransationDeclined]);

  /* Credit Card View */
  const getInlinePaymentUI = () => {
    if (
      checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CREDIT_CARD &&
      !checkoutDetails?.isLastUsedPaymentIsFromSavedCard
    ) {
      return <KBankPaymentCreditDebitCardForm {...props} />;
    }
    if (checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.TRUE_MONEY_WALLET) {
      return <TrueMoneyWalletTemplate {...props} />;
    }
  };

  return (
    <>
      {/* Payment view */}
      {getPaymentOptionsTemplate(props, getInlinePaymentUI)}
      {/* Hidden Forms */}
      <div className='hidden'>
        {/* QR pay */}
        {checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.QR_PAY && <KBankPaymentQRCodeForm {...props} />}
      </div>
    </>
  );
};

export default React.memo(ThailandPaymentView);
